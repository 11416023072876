.main-area {
    padding-top: 40px;
    position: relative;
}

.dashed-line {
    height: 1px;
    border-bottom: 2px dashed black;
    position: absolute;
    top: 66px;
    display: none;
}

/* .clearfix::after {
    display: block;
    clear: both;
    content: "";
} */
.heading-area {
    margin: 0 auto;
    width: 53px;
    height: 54px;
    background-color: #4d4d4d;
    outline: 10px solid white;
}

.heading {
    font-size: 36px;
    font-weight: 800;
    padding-top: 5px;
    margin-top: 0;
    color: #fba928;
    font-family: "Montserrat", sans-serif;
}

.paragraph {
    margin-top: 20px;
    text-align: center;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 400;
    color: #273846;
    font-family: "Montserrat", sans-serif;
}

.text_dark {
    color: #4d4d4d;
}

.formcontrol {
    background-color: transparent;
    border: 1px solid #4d4d4d;
    color: #4d4d4d;
    font-size: 14px;
    padding: 0 9px;
    border-radius: 6px;
    height: 47px !important;
    display: block;
    width: 100%;
}

textarea#description {
    height: 94px !important;
}

.btn_light {
    display: block;
    width: 100%;
    height: 45px;
    /* margin-top: 20px; */
    border-radius: 6px;
    background: #fba928;
    color: #4d4d4d;
    font-weight: 800;
    text-transform: uppercase;
    border: none;
}

.btn_light:hover {
    color: #fff;
    background: #4d4d4d;
    outline: none;
    border: none;
}

.bg_gray {
    background: #f4f5f6;
    padding: 25px;
}

.sb_contact_list h5 {
    font-weight: 600;
}

.sb_contact_list h6 {
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 15px;
}

.sb_contact_list ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #273947;
    font-weight: 500;
}

.sb_contact_list ul li {
    vertical-align: top;
    margin-top: 5px;
    display: block;
}

/* .footer-loc-elem {
    position: absolute;
    left: 5%;
    bottom: -55%;
    width: 46%;
} */

.sb_contact_list h1 {
    margin: 30px 0;
    border-bottom: 1px solid #273846;
    width: 56%;
}

.sb_contact_list ul li {
    vertical-align: top ;
    margin-top: 5px ;
    display: block ;

}

.border-none {
    border: none !important;
}