footer {
    background: #4d4d4d;
    padding: 50px 0;
    border-bottom: 10px solid #4d4d4d;
}

footer h3 {
    color: #fff;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 26px;
}

footer p {
    color: #fff;
    font-size: 11px;
    text-align: justify;
}

ul.footer_social_icons {
    padding: 0;
    list-style: none;
}

ul.footer_social_icons li {
    display: inline-block;
}

footer ul li {
    margin-bottom: 4px;
    color: #fff;
}

ul.footer_social_icons li a {
    color: #fff;
    display: block;
    padding: 4px 12px;
    font-size: 15px;
}

footer ul li a:hover {
    color: #ffb400;
    text-decoration: none;
}

footer ul li.active a {
    color: #ffb400;
}

.f_border:before {
    content: "";
    position: absolute;
    top: 45px;
    left: 0px;
    width: 1px;
    height: 290px;
    background: #ffb400;
}

footer h3 {
    color: #fff;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 26px;
}

footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

footer ul li {
    margin-bottom: 3px;
    display: block;
    color: #fff;
}

footer ul li a {
    color: #fff;
    font-size: 12px;
}
.mail{
    font-size:11px !important;
}
.f_border {
    position: relative !important;
}

.col_2 {
    padding-left: 14px;
}

.f_brands img {
    width: 28%;
    margin: 0px 5px 29px 0;
}

footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.f_address li {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 12px;
}

footer ul li {
    display: block;
    color: #fff;
}

ul.f_address li i {
    display: block;
    margin-bottom: 5px;
    color: #ffb500;
    font-size: 18px;
}

ul.f_address li a {
    display: block;
    font-size: 14px;
}

footer ul li a {
    color: #fff;
}

@media (max-width: 1024px) {
    .col_1 {
        padding-right: 0;
        margin-bottom: 10px;
        border-bottom: 1px solid #ffb400;
        padding-bottom: 20px;
        padding-left: 0px;
    }
}
@media (max-width: 967px) {
    footer h3 {
        color: #fff;
        font-size: 24px;
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 26px;
    }
    footer p {
        color: #fff;
        font-size: 16px;
        text-align: justify;
        padding-right:10px;
    }
    .f_brands img {
        width:11%;
    }
}

@media (max-width: 767px) {
    footer ul {
        column-count: 2;
    }
    .col_2,
    .col_3,
    .col_4 {
        border-bottom: 1px solid #ffb400;
        padding-bottom: 15px;
        margin-bottom: 10px;
    }
    footer p {
        color: #fff;
        font-size: 12px;
        text-align: justify;
        padding-right:10px;
        padding-left:10px;
    }
    .f_brands img {
        width:25%;
    }
    
}