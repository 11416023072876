section.thank-you-sec {
    /* height: 80vh; */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}

.thank-you-icn h4 {
    height: 130px;
    width: 130px;
    font-family: 'FontAwesome';
    margin: auto;
    color: #fff;
    background: #ffb400;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 70px;
    text-align: center;
    line-height: 0;
}

.thank-you-cnt h1 {
    font-size: 45px;
    
    font-weight: 700;
    padding: 20px 0 0;
}

.sbscribe-form form {
    width: 100%;
    margin: auto;
    padding: 40px 25% 0 25%;
}

.sbscribe-form .sub_inp input {
    border: 1px solid #4d4d4d;
    height: 50px;
    padding: 0 40px 0 70px;
    text-align: left;

    width: 100%;
    border-radius: 5px;
    position: relative;
}

.sbscribe-form .sub_inp .bi-envelope {
    /* content: "\f003"; */
    font-family: FontAwesome;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 20px;
    font-size: 28px;
    height: 47px;
    width: 47px;
    line-height: 47px;
    color: #273846;
    z-index: 1;
    position: absolute;
}

.sbscribe-form .sub_inp {
    width: 100%;
    position: relative;
}

.sbscribe-form .sub_inp input:hover {
    border: 1px solid #ffb400;
}
.sub_inp-submit button {
    background: #fba928;
    color: #fff;
    
    padding: 12px 6px;
    margin-top: 60px;
    border-radius: 6px;
    text-transform: uppercase;
    width: 50%;
    font-weight: 800;
    font-size: 14px;
    cursor: pointer;
    height: 50px;
    border: 1px solid #fba928;
}
.sub_inp-submit button:hover {
    background: #ffb400;
    border: 1px solid #4d4d4d;
}
.sbscribe-social {
    padding: 30px 0 0;
}
.thank-you-social h5 {
    font-size: 21px;
    font-weight: 700;
    padding-bottom: 30px;
}
.thank-you-social ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.thank-you-social ul li {
    display: inline-block;
    vertical-align: top;
}
.thank-you-social ul li a {
    height: 40px;
    width: 40px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #4d4d4d;
    font-size: 20px;
    color: #4d4d4d;
    line-height: 0;
    transition: 0.5s all ease;
}
.thank-you-social ul li a:hover {
    color: #ffb400;
    border-color: #ffb400;
    
}
@media (max-width:767px) {
 .thank-you-cnt h1 {
    font-size: 26px;
    
    font-weight: 700;
    padding: 20px 0 0;
}   
.sbscribe-form form {
    width: 100%;
    margin: auto;
    padding: 40px 0 0 0;
}
}

