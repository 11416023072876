.sold-equipment_banner {
    position: relative;
    width: 100%;

    min-height: 550px;
    overflow: hidden;
}

.sold-wheel-loaders_banner {
    background: url("../../../public/images/Banner-wheel-loader.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.sold-wheel-excavators_banner {
    background: url("../../../public/images/Banner-wheel-excavator.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.sold-track-loader_banner {
    background: url("../../../public/images/Banner-track-loader.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.sold-telehandler_banner {
    background: url("../../../public/images/Banner-telehandler.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.sold-parts_banner {
    background: url("../../../public/images/Banner-parts.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.sold-roller_banner {
    background: url("../../../public/images/banner-roller.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.sold-attachments_banner {
    background: url("../../../public/images/Banner-new-attachment.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.sold-backhoe_banner {
    background: url("../../../public/images/Banner-backhoe.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.sold-dozer_banner {
    background: url("../../../public/images/Banner-dozer.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.sold-list_banner {
    background: url("../../../public/images/Banner-SOLD.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.sold-dump-truck_banner {
    background: url("../../../public/images/Banner-dump-truck.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.sold-excavator_banner {
    background: url("../../../public/images/Banner-excavator.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.sold-mini-excavator_banner {
    background: url("../../../public/images/Banner-excavator.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.sold-motor-graders_banner {
    background: url("../../../public/images/Banner-motor-Grader.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.sold-skid-steer_banner {
    background: url("../../../public/images/Banner-skidsteer.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}