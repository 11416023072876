.case-banner {
    background: url("../../../public/images/Banner-CASE.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}

.caterpillar-banner {
    background: url("../../../public/images/Banner-caterpillar.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}

.doosan-banner {
    background: url("../../../public/images/Banner-Doosan.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}

.hitachi-banner {
    background: url("../../../public/images/Banner-Hitachi.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}

.john-deere-banner {
    background: url("../../../public/images/Banner-deere.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}

.komatsu-banner {
    background: url("../../../public/images/Banner-komatsu.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}

.volvo-banner {
    background: url("../../../public/images/Banner-Volvo.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}

.banner_padding {
    padding-top: 210px;
    padding-bottom: 50px;
}

.title_with_sep {
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
}

.title_with_sep h3 {
    color: #4b4b4b;
    font-size: 29.97px;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 18px;
}

.eq_brands .content ul li {
    position: relative;
    margin-bottom: 10px;
    line-height: 1.7;
    font-size: 14px;
    padding-left: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: unset !important;
}

.brands_logos ul li {
    display: inline-block;
    border: solid 1px #fba928;
    border-radius: 7px;
    padding: 8px;
    margin-right: 5px;
    margin-left: 5px;
}

.brands_logos ul li:hover {
    border: solid 1px #4d4d4d;
}

@media (max-width: 1024px) {
    .title_with_sep h3 {
        font-size: 26px;
    }
}