header.transparent {
    position: fixed;
    z-index: 1;
    top: 0px;
    left: 0px;
    width: 100%;
}





.topbar {
    position: relative;
    height: 35px;
    border-bottom: 1px solid #475662;
}

.pull-left {
    float: left;
    margin-top: 8px;
}

.pull-right {
    float: right;
}

.form_row .icon_search {
    position: absolute;
    top: 46%;
    left: 10px;
    text-align: center;
    font-size: 25px;

    -webkit-transform: translateY(-50%);
    color: #fba928;
}

.form_row input[type="text"] {
    width: 252px;
    border: 1px solid #475662;
    border-radius: 5px;
    height: 25px;
    padding: 0 42px;
    color: #fff;
    font-size: 14.39px;
    outline: none;
    background: transparent;
}

.form_row .microphone {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    font-size: 25px;
    color: #fba928;
}

ul.social_icons {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
}

ul.social_icons li a {
    color: #fff;
    padding: 0 14px;
    font-size: 15px;
    line-height: 42px;
}

ul.social_icons {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
}

ul.social_icons li {
    display: inline-block;
}

ul.social_icons li a:hover {
    color: #fba928;
    outline: none;
    text-decoration: none;
}

.topbar_btn {
    border: none;
    background: transparent;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    padding: 0 9px;
    font-weight: 600;
}

a.topbar_btn.topbar_btn2 {
    padding: 1px 6px;
    background: #ffb400;
    border: 1px solid #ffb400;
    border-radius: 5px;
    color: #273947;
}

a.topbar_btn.topbar_btn2:hover {
    background: transparent;
    color: #fff;
}

.topbar_btn:hover,
.topbar_btn:focus {
    color: #fba928;
    outline: none;
    text-decoration: none;
}

.navbar-toggler {
    position: relative;
    background: #fba928;
    color: #fff;
    width: 40px;
    padding: 8px;
    height: 40px;
    border-radius: 0px;
    z-index: 9999;
    font-size: 1.25rem;
}

.navbar-toggler .menu_toggle_close {
    display: none;
}

.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

.c_navbar {
    border-bottom: 1px solid #475662;
    transition: all 0.5s ease;
}

.c_navbar ul li.active>a {
    color: #fba928;
}

.c_navbar a.nav-link {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.navbar-nav {
    list-style: none !important;
}

.btn_navbar span {
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    padding: 2px 10px;
    border: 1px solid #fba928 !important;
    text-align: center;
}

.c_navbar a.nav-link {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.c_navbar ul li a:hover {
    color: #fba928;
}

.btn_navbar:hover span {
    color: #273846 !important;
    text-decoration: none;
    background: #fba928;
}

.fixed_menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #4d4d4d;
    z-index: 999;
    border-bottom: none;
}

/* .carousel-item.active {
    opacity: 0;
} */

.collapse.show .menu_overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 20%;
    height: 100vh;
    background: #27384666;
    z-index: -1;
}

.navbar>.container {
    flex-wrap: nowrap;
    /* display: flex; */
}

.navbar-nav .nav-link.active {
    color: #fba928;
}

.navbar .dropdown-menu {
    border: none;
    background-color: #4d4d4d !important;
    padding: 0px;
    border-radius: 0px;
}

.mx-8 {
    margin-right: -10px !important;
    margin-left: -10px !important;
}

.px-8 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

a:hover {
    color: #fba928;
}

.top-bar-submenu-ul {
    padding-left: 0px;
    margin-bottom: 20px;
    min-height: 60px;
    /* display:flex;
    flex-direction: row;
    justify-content: start;
    align-items: center; */
}
.top-bar-submenu-ul div {
    list-style: none;
}
.top-bar-submenu-ul div a {
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    display: block;
    position: relative;
    font-weight: 500;
}
.has-child-menu .child-menu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 100%;
    list-style: none;
    width: 480px;
}


@media screen and (min-width: 992px) {
    .mx-50{
        margin-left: 0;
    }
    .navbar .dropdown:hover .dropdown-menu {
        
        visibility: visible;
        opacity: 1;
    }

    a.services_mega_menu:hover span {
        color: #ffb400;
    }

    a.services_mega_menu {
        display: inline-block;
        width: 100%;
        background: #fff;
        text-align: center;
        padding: 8px;
        border-radius: 5px;
        border-bottom: 7px solid #ffb400;
    }

    a.services_mega_menu img {
        width: 158px;
        max-height: 109px;
    }

    a.services_mega_menu span {
        color: #273846;
        display: block;
        padding-top: 14px;
        padding-bottom: 5px;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
    }

    .navbar .dropdown:hover .dropdown-menu {
        display: block;
        visibility: visible;
        opacity: 1;
    }

    .badge {
        background-color: #ffb400;
        top: -10px;
        right: -5px;
        box-shadow: 0 0 10px #273846;
        position: absolute !important;
        font-weight: 400 !important;
        font-size: 15px !important;
        color: #fff !important;
        margin-top: 0 !important;
    }

    .single_megamenu:hover span {
        color: #ffb400;
    }

    a.single_megamenu:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 100%;
        height: 7px;
        background: #ffb400;
        border-radius: 0 0 5px 5px;
    }

    a.single_megamenu span {
        color: #273846;
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
    }

    a.single_megamenu img {
        width: 100%;
    }

    a.single_megamenu {
        position: relative;
        width: 100%;
        display: inline-block;
    }

    .megamenu_title p {
        font-size: 18px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 0px;
        line-height: 1.2;
    }

    .megamenu_title h3 {
        font-size: 36px;
        font-weight: 800;
        color: #fff;
        margin: 0;
        text-transform: uppercase;
        padding-top: 20px;
    }

    .megamenu_title {
        border-left: 7px solid #ffb400;
        padding-left: 15px;
        margin-bottom: 35px;
    }

    .navbar .dropdown:hover .dropdown-menu {
        display: block;
        visibility: visible;
        opacity: 1;
    }

    .navbar .dropdown-menu {
        width: 100%;
        left: 0;
        right: 0;
        top: 97%;

        border-top: 1px solid #475662;
    }

    .navbar .dropdown {
        position: static;
    }

    .navbar .dropdown:hover .dropdown-menu,
    .navbar .dropdown .dropdown-menu:hover {
        display: block;
        visibility: visible;
        opacity: 1;
    }
}

@media (max-width: 991px) {
    .navbar-collapse {
        position: fixed;
        top: 0;
        left: 0;
        width: 80%;
        padding: 40px 20px;
        height: 100vh;
        background: #4d4d4d;
        z-index: 999;
        overflow-y: auto;
    }
}

@media (max-width: 767px) {
    .topbar ul.social_icons {
        display: block;
    }

    .home_banner_slider {
        padding-top: 230px;
        padding-bottom: 40px;
    }

    #banner_vedio {
        display: none !important;
    }

    .topbar .pull-left,
    .topbar .pull-right {
        width: 100%;
        text-align: center;
    }

    .topbar .pull-right {
        padding-right: 0px;
    }

    .c_navbar {
        padding-bottom: 5px !important;
    }

    .topbar {
        padding: 10px 15px 4px;
        height: auto;
        display: inline-block;
        width: 100%;
        padding-bottom: 10px;
    }
}