.an-primary-heading.an-center {
    text-align: center;
}
.an-primary-heading.an-center::before {
    left: 50%;
    transform: translateX(-50%);
}
.an-primary-heading::before {
    content: "";
    position: absolute;
    width: 150px;
    height: 5px;
    background: #ffb400;
    bottom: 0px;
}

.an-primary-heading {
    font-size: 30px;
    font-weight: 800;
    text-transform: uppercase;
    color: black;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 30px;
}
.an-space-4 {
    margin-left: -4px;
    margin-right: -4px;
}
.an-product-box {
    overflow: hidden;
    position: relative;
    padding-right: 10px;
}
.an-product-box .an-product-content {
    padding: 10px 5px;
    background: #eeeeee;
    text-align: center;
}