.navigation_btn a {
    color: #4d4d4d;
    font-weight: 400;
    text-transform: uppercase;
}

.single_eq_title {
    font-size: 35px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 0px;
}
.single_eq_price {
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.single_eq_slider .carousel-inner {
    border: 1px solid #4d4d4d;
    max-height: 540px;
}

.detail_custom_thumbnail li {
    list-style: none;
}

.detail_custom_thumbnail li img {
    width: 116px;
    height: 68px;
    border: 1px solid #4d4d4d;
}

.playbutton {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    margin: 0 auto;
    /* transform: translate(-50%, -50%); */
    -webkit-transform: translate(-50%, -50%);
    text-align: center;
}

.playbutton img {
    width: 57px !important;
    height: auto !important;
    border: none !important;
}

.px-10 {
    padding-left: 5px;
    padding-right: 5px;
}

.mx-10 {
    margin-left: -5px;
    margin-right: -5px;
}

.morephotos {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.moreoverlay {
    width: 91%;
    height: 100%;
    position: fixed;
    background: #000000d1;
    position: absolute;
    left: 5px;
    font-size: 19px;
    line-height: normal;
    color: #fff;
    text-align: center;
    padding-top: 12px;
}

.single_eq_content {
    border-top: 1px solid #4d4d4d;
    padding-top: 23px;
}

.single_eq_content h3 {
    position: relative;
    color: #4d4d4d;
    font-size: 18px;
    font-weight: 800;
    padding-bottom: 18px;
    margin-bottom: 25px;
}

.single_eq_content h3:before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 47px;
    height: 5px;
    background: #ffb400;
}

.more_option a {
    color: #4d4d4d;
    border: 1px solid #4d4d4d;
    font-size: 12px;
    display: inline-block;
    padding: 14px 10px;
    border-radius: 5px;
    text-decoration: none;
    margin-right: 4px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.more_option a:hover {
    color: #ffb400;
    border: 1px solid #ffb400;
    text-decoration: none;
}

.more_option a i {
    font-size: 17px;
    vertical-align: middle;
    margin-bottom: 3px;
    margin-right: 2px;
}

.sidebar_form {
    background: #f4f5f6;
    padding: 50px 22px;
    margin-top: 110px;
    border-radius: 10px;
}

.sidebar_form h3 {
    font-size: 22px;
    font-weight: 800;
    text-transform: uppercase;
}

.sidebar_form form input {
    display: block;
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
    color: #4d4d4d;
    height: 45px;
    padding: 0 8px;
    border: 1px solid #4d4d4d;
    border-radius: 6px;
}

.sidebar_form form textarea {
    display: block;
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
    color: #4d4d4d;
    padding: 9px 8px !important;
    border: 1px solid #4d4d4d;
    border-radius: 6px;
    height: 45px;
}

.sidebar_form button {
    display: block;
    width: 100%;
    height: 45px;
    margin-top: 20px;
    border-radius: 6px;
    background: #ffb400;
    color: #4d4d4d;
    font-weight: 800;
    text-transform: uppercase;
    border: none;
}

.sidebar_form button:hover {
    background: #4d4d4d;
    color: #fff;
}

.sb_contact_list ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #273947;
    font-weight: 400;
}

.sb_contact_list ul li {
    margin-top: 20px;
    position: relative;
    padding-left: 45px;
}

.sb_contact_list ul li i {
    position: absolute;
    /* top: 2px; */
    left: 0;
    font-size: 20px;
    color: #ffb400;
    width: 40px;
    text-align: center;
}

.sb_contact_list ul li a {
    color: #273947;
    display: block;
}

.Ads_banner {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    padding: 35px;
}

.Ads_banner h3 {
    font-size: 30px;
    font-weight: 800;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.title_with_sep {
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
}

.title_with_sep h3 {
    color: #4b4b4b;
    font-size: 29.97px;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 18px;
}

section.inventory_section .cbtn_dark {
    font-weight: 600;
}
.detail__specs-label {
    color: #666;
    padding: 10px 15px;
    background-color: #f3f3f3;
    font-weight: 700;
}



.detail__specs-wrapper {
    display: grid;
    gap: 4px 5px;
    margin-bottom: 20px;
}
.detail__specs-value {
    padding: 10px 15px;
    border-bottom: 1px #eaeaea solid;
    word-break: break-word;
    white-space: pre-wrap;
}
.wwwetiglobalcom-w4b0yk {
    color: #666666;
    background-color: #f3f3f3;
    border-radius: 10px;
}
.dealer-contact__header{
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 6px;
    border-bottom: 1px #ddd solid;
}
.wwwetiglobalcom-1v2zh90 {
    margin: 0;
    font-size: 1.125rem;
    line-height: 1.2;
    font-family: Open Sans, sans-serif;
    font-weight: 300;
    background-color: inherit;
    font-weight: 700;
}
.dealer-contact__branch-name {
    margin-right: 5px;
    font-weight: bold;
}


@media screen and (min-width: 1001px) {
    .dealer-contact__details {
        display: grid;
        grid-template-columns: repeat(2, [ col-start ] 1fr);
        gap: 20px;
    }
    .detail__specs-wrapper {
        grid-template-columns: [ col-start] 25% [ col-start] 75%;
    }
    .dealer-contact {
        margin: 15px 0;
        padding: 20px 25px;
        border-radius: 10px;
    }
}



@media (min-width: 992px) {

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }
}
@media (max-width: 767px){
    .single_eq_title ,
    .single_eq_price {
    font-size: 26px;
    
}
}