.inventory_section {
    /* background-image: url("./../../../public/images/homebg1.png"); */
    /* background-image: url(https://www.my-equipment.com/404banner-min.png); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    padding: 30px 0;
}

.title_with_sep h3 {
    color: #4b4b4b;
    font-size: 29.97px;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 18px;
}

.divider {
    background: #fba928;
    width: 147px;
    height: 5px;
}

.home-inventory_product_slider .inventory_product {
    margin: 0;
    padding: 0 5px;
    vertical-align: top;
}

.new-label {
    padding: 3px 5px;
    position: absolute;
    /* top: 0;
    left: 0; */
    background: #479b55;
}

.new-label:before {
    content: 'New Arrival';
    font-size: 11px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    position: relative;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid-product {
    margin: 0 100px;
}

.inventory_product .image {
    position: relative;
    border: 1px solid #4b4b4b;
    border-bottom: none;
}

.inventory_product .image img {
    max-height: 135px;
    width: 100%;
    height: auto;
}
#img-search{
    max-height: 260px;
    width: 100%;
    height: auto;
}

.an-blog-box figure {
    overflow: hidden;
    border: 1px solid #ebebeb;
    border-radius: 15px;
    margin-bottom: 10px;
}

.an-blog-box img {
    width: 100%;
    object-fit: cover;
}

.an-blog-box h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.an-blog-box p {
    font-size: 13px;
    color: black;
    font-weight: 600;
}

.an-latest-inventary-breadcrum {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #9a9c9f;
    margin-bottom: 20px;
    font-size: 12px;
    color: #727272;
    align-items: center;
    text-transform: uppercase;
    font-weight: 500;
}

.an-latest-inventary-breadcrum span {
    padding: 0px 2px;
    text-transform: uppercase;
}

.inventory_product .image p {
    /* position: absolute;
    bottom: 0;
    right: 0; */
    margin: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 800;
    padding: 2px 8px;
    background: #4b4b4b;
}

.inventory_product h3 {
    border-top: 5px solid #fba928;
    font-size: 18px;
    color: #4b4b4b;
    font-weight: 800;
    padding-top: 12px;
    line-height: 1.4;
}

.inventory_product h3 a {
    text-decoration: none;
    color: #4b4b4b;

}

.inventory_product h3 a:hover {
    text-decoration: none;
    color: #fba928;
}

.inventory_product h3 span {
    display: block;
    font-weight: 600;
    font-size: 14.69px;
}

.inventory_section-btn a {
    background: #353535;
    color: #fff;
}

.inventory_section-btn a:hover {
    background: #fba928;
    color: #ffffff;
}

.cbtn_dark {
    color: #4b4b4b;
    text-transform: uppercase;
    border: 1px solid #4b4b4b;
    border-radius: 5px;
    font-size: 13.99px;
    display: inline-block;
    padding: 12.5px 21px;
}

.cbtn_dark:hover {
    text-decoration: none;
    border-color: #fba928;
    color: #fba928;
}

/* filter section */
.detail_search {
    overflow: hidden;
    position: relative;
    background: url("../../../public/images/want machines.png") #4b4b4b;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 60px;
    background-position: 85% 0;
}

.border_title {
    position: relative;
    font-size: 58px;
    color: #fba928;
    font-weight: 800;
    text-transform: uppercase;
    border-left: 22px solid #fba928;
    padding-left: 22px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.border_title-brands {
    position: relative;
    font-size: 58px;
    color: #4d4d4d;
    font-weight: 800;
    text-transform: uppercase;
    border-left: 22px solid #4d4d4d;
    padding-left: 22px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.form_select {
    position: relative;
    padding-top: 30px;
    width: 18.9%;
    display: inline-block;
    margin-right: 1%;
}

label {
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    margin-bottom: 11px;
}

.form-control {
    background-color: transparent;
    color: #fff;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #ffffff;
}

.form_select {
    position: relative;
    padding-top: 30px;
    width: 18.9%;
    display: inline-block;
    margin-right: 1%;
}

select {
    appearance: none;
    background-image: url(https://www.my-equipment.com/newsite/images/select_arrow.png);
    background-repeat: no-repeat;
    background-position: 100% 0;

}

span.select2-selection__arrow {
    height: 45px !important;
    background-image: url(https://www.my-equipment.com/newsite/images/select_arrow.png);
    background-repeat: no-repeat !important;
    background-position: 100% 0 !important;
    width: 47px !important;
}

.select2-container--default .select2-selection--single {
    background-color: transparent !important;
    border: 1px solid #ffffff !important;
    color: #fff !important;
    font-size: 14px !important;
    /* padding: 0 9px !important; */
    border-radius: 6px !important;
    line-height: 47px !important;
    height: 47px !important;
}

.form_select button {
    color: #4b4b4b;
    font-size: 14px;
    font-weight: 800;
    background: #fba928;
    border: 1px solid #fff;
    text-transform: uppercase;
    padding: 12px 15px;
    border-radius: 6px;
    width: 100%;
}

.form_select button:hover {
    outline: none;
    cursor: pointer;
    color: #fff;
    background: #273846;
}

/* new arrivals */
.new_arrival_section {
    background-image: url(https://www.my-equipment.com/themes/v1/images/home/03.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

.title_with_sep {
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
}

.title_with_sep h3 {
    color: #273846;
    font-size: 29.97px;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 18px;
}

.inventory_product {
    position: relative;
    margin: 0 5px 0 5px;
    margin-bottom: 18px;
    padding: 0px 1px;
}

ul.single_eq_list {
    list-style: none;
    margin: 0;
    padding: 0;
    min-height: 70px;
}

ul.single_eq_list li {
    display: inline-block;
    font-size: 14px;
    color: #273846;
    padding-right: 15px;
    margin-bottom: 12px;
}

.inventory_product .image p {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 2px 15px;
    background: #4d4d4d;
}

/* construction section */
.custruction_section {
    background: url("../../../public/images/Banner-search.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 95% 0;
    padding-bottom: 40px;
}

/* .border_title {
    position: relative;
    font-size: 58px;
    color: #fba928;
    font-weight: 800;
    text-transform: uppercase;
    border-left: 22px solid #ffb400;
    padding-left: 22px;
    padding-top: 30px;
    padding-bottom: 30px;
} */

.inner_title {
    color: #fff;
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
}

.input_price {
    position: relative;
}

.input_price input {
    padding-left: 50px;
}

.input_price:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 46px;
    height: 47px;
    display: inline-block;
    background: url(https://www.my-equipment.com/newsite/images/price.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.btn_orange {
    height: 53px;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    background: #fba928;
    color: #273846;
    border: 1px solid #fff;
}

.btn_orange:hover {
    border: 1px solid #fff;
    outline: none;
    box-shadow: none;
    background: transparent;
    color: #fff;
}

.btn_link_orange {
    font-size: 13.99px;
    border: 1px solid #fba928;
    color: #fff;
    padding: 12px 15px;
    border-radius: 6px;
    display: inline-block;
    text-transform: uppercase;
}

.btn_link_orange:hover {
    color: #fba928;
    text-decoration: none;
}

/* our Service section */
.our_service {
    background-image: url(https://www.my-equipment.com/themes/v1/images/home/02.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

.title_with_sep {
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
}

.title_with_sep h3 {
    color: #4d4d4d;
    font-size: 29.97px;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 18px;
}

.img-fluid{
    max-height: 228px;
}

.service_box {
    background-color: white;
    border: 1px solid #4d4d4d;
    border-bottom: 10px solid #4d4d4d;
    margin-top: 30px;
}

.service_box h3:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 28px;
    height: 74px;
    background: #fba928;
}

.service_box h3 {
    font-size: 24px;
    color: #4b4b4b;
    font-weight: 800;
    text-transform: uppercase;
    position: relative;
    padding: 23px 10px 24px 40px;
    margin: 0;
}

.service_desc {
    padding: 0px 16px 10px 40px;
}

.service_box p {
    font-size: 15px;
    min-height: 133px;
}

.service_box p span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    color: #4d4d4d;
}

.service_box a {
    font-size: 14px;
    color: #4d4d4d;
    font-weight: 500;
    border-bottom: 1px solid #4d4d4d;
    margin-bottom: 20px;
    display: inline-block;
}

.an-section-padding {
    padding: 60px 0px;
}

.img-wrapper {
    max-width: 100%;
    height: 10em;
    display: flex;
    justify-content: center;
    align-items: self-start;


}

.carousel-inner {
    padding: 1em;
}

.card {
    margin: 0 0.5em;
}
.card .img-wrapper img{
    max-width: 100%;
    max-height: 100%;
}
.custruction_section-2{
    background: url("../../../public/images/Banner-search.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 95% 0;
    padding-bottom: 40px;
}

@media screen and (main-width:575px) {
    .carousel-inner {
        display: flex;
    }

    .carousel-item {
        display: block !important;
        margin-right: 0;
        flex: 0 0 calc(100%/3);
    }
    .img-wrapper {
        max-width: 100%;
        height: 23vw;
    
    }
}


@media (max-width: 1024px) {
    .service_box h3 {
        font-size: 18px;
        padding: 20px 10px 20px 35px;
    }
}

@media (max-width: 991px) {
    .border_title {
        font-size: 28px !important;
    }

    .border_title {
        font-size: 28px;
    }
}

@media (max-width: 767px) {
    .border_title-brands {
        font-size: 36px;
    }
    .inventory_product .image img {
        max-height: 135px;
        width: 100%;
        height: auto;
    }
    .new_arrival_section {
        background-image: none;
    }

    .inventory_section {
        background-image: none;
    }

    .form_select {
        width: 100%;
        margin: 0;
    }

    .detail_search {
        padding-bottom: 40px;
        background: url(https://www.my-equipment.com/newsite/images/detail_tracter_new.png) #273846;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 37% 0;
    }

    .custruction_section {
        background-position: 90% 0;
    }

    .grid-product {
        margin: 0px;
    }
}