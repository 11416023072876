

.img-404 {
    width: auto;
}

.content_area ul {
    list-style: none;
    margin: 0px;
}

.content_area ul li:before {
    content: "";
    position: absolute;
    top: 6px;
    left: 0px;
    width: 8px;
    height: 8px;
    background: none;
    display: inline-block;
    border-radius: 10px;
}
@media only screen and (min-width: 801px) {
    #notfound {
        background: url(https://www.my-equipment.com/404banner-min.png);

        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
        position: relative;
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 0em 0em;
    }

}

@media (max-width: 767px) {
    .img-404 {
        width: -webkit-fill-available;
    }
}