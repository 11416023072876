.an-chat-button {
    background: transparent;
    border: none;
    padding: 0px;
    position: fixed;
    right: 7px;
    bottom: 0px;
    z-index: 999;
}

.an-sidebar-cta {
    width: 40px;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    right: 0px;
}

.an-sidebar-cta>div {
    width: 430px;
    background: white;
    margin-bottom: 10px;
    border-bottom-left-radius: 6px;
    overflow: hidden;
    border-top-left-radius: 6px;
    position: relative;
    border: 1px solid #d9d9d9;
}

.an-cta-phone button {
    height: 40px;
    width: 40px;
    border: none;
    background: #fba928;
}

.an-cta-phone a {
    font-size: 24px;
    line-height: 24px;
    font-weight: 800;
    color: black;
    display: inline-block;
    padding: 0px 11px;
    position: relative;
    /* top: 3px; */
    top: 0px;
    left: -8px;
}

.an-cta-form>button {
    position: absolute;
    /* height: 40px;
    width: 446px;
    left: -203px; */
    height: 40px;
    width: 637px;
    left: -298px;
    transform: rotate(-90deg);
    top: 200px;
    border: none;
    background: #fba928;
    font-size: 19px;
    font-weight: 700;
    text-transform: uppercase;
}

.an-cta-form {
    padding: 10px;
    padding-left: 50px;
    transition: 0.5s;
    left: 0px;
}

.an-cta-form>button img {
    transition: 0.5s;
    transform: rotate(90deg);
}

.an-cta-form.active {
    left: -389px;
}

.an-cta-form.active>button img {
    transform: rotate(-90deg);
}

.an-cta-form .row>div {
    padding: 0px 5px;
    margin-bottom: 15px;
}

.an-cta-form .row {
    margin: 0px;
}

.cta-form .row .col-lg-6 label {
    font-size: 13px;
    margin-bottom: 0;
    font-weight: 800;
    text-transform: uppercase;
    color: black
}

.cta-form .row .col-lg-12 label {
    font-size: 13px;
    margin-bottom: 0;
    font-weight: 800;
    text-transform: uppercase;
    color: black
}

.an-req {
    color: red;
}

.an-cta-form .form-control {
    height: 45px;
    font-size: 13px;
    border: 1px solid #ced4da;
}

.an-cta-form .an-theme-btn {
    width: 100%;
    font-weight: 800;
    font-size: 12px;
}

.an-theme-btn.dark {
    background: #fba928;
    border: 1px solid #fba928;
    color: black;
    padding: 6px 20px;
    border-radius: 6px;
    font-weight: 700;
    cursor: pointer;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.an-theme-btn.dark:hover {
    background: white;
}

button {
    transition: 0.5s;
}

@media screen and (max-width: 992px) {

    .an-sidebar-cta {
        width: 40px;
        position: fixed;
        top: 70%;
        transform: translateY(-50%);
        z-index: 999;
        right: 0px;
    }
}