.bg_dark {
    background: #4d4d4d;
}

.navigation_border {
    border-bottom: 1px solid #4d4d4d;
}

.navigation_btn a {
    color: #4d4d4d;
    font-weight: 400;
    text-transform: uppercase;
}

.navigation_btn a:hover,
.navigation_btn a:focus {
    text-decoration: none;
    color: #fba928;
}

.navigation_btn a i {
    font-size: 20px;
    font-weight: 1000;
    /* vertical-align: middle; */
}

.img_border {
    border: 1px solid #4d4d4d;
    border-bottom: 4px solid;
}

.service_content h2:before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 50px;
    height: 5px;
    display: block;
    background: #fba928;
}

.service_content h2 {
    position: relative;
    font-size: 30px;
    color: #4d4d4d;
    font-weight: 800;
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.equipment_banner_slider,
.banner_padding {
    padding-top: 100px;
    padding-bottom: 80px;
}

.services_banner {
    position: relative;
    width: 100%;


    min-height: 650px;
    overflow: hidden;
}

.services_banner {
    background-repeat: no-repeat !important;
    /* background: url(https://www.my-equipment.com/newsite/images/services/banner/shipping-forwarding.png) #4d4d4d; */
    background-position: center;
}

.lg_text-service {
    font-size: 60px;
    font-weight: 800;
    color: #273846 !important;
    text-transform: uppercase;
}

/* 
.border_title {
    position: relative;
    font-size: 58px;
    color: #fba928;
    font-weight: 800;
    text-transform: uppercase;
    border-left: 22px solid #ffb400;
    padding-left: 22px;
    padding-top: 30px;
    padding-bottom: 30px;
} */

.content_padding {
    position: relative;
    padding: 20px 20px 30px 50px;
}

.content_padding:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 36px;
    height: 95px;
    background: #fba928;
}

.content_padding h3 {
    padding-bottom: 30px;
    padding-top: 12px;
    margin-bottom: 0px;
    color: #fba928;
}

.font24_extrabold {
    font-size: 24px;
    font-weight: 800;
}

.content_padding a {
    font-weight: 600;
    border-bottom: 1px solid #fff;
    color: #fff;
}

/* .border_title.txt_dark {
    border-left: 22px solid #4b4b4b;
} */

.txt_dark {
    color: #4d4d4d;
}

.img-banner {
    width: 100%;
    max-height: 600px;
}

.an-content-banner-shipping {
    background-image: url("../../../public/images/shipping.jpg");
    min-height: 600px;
    display: flex;
    align-items: center;
    color: white;
    padding: 40px;
    border-radius: 15px;
    background-size: cover;
}
.an-content-banner-transport {
    background-image: url("../../../public/images/transport.jpg");
    min-height: 684px;
    display: flex;
    align-items: center;
    color: white;
    padding: 40px;
    border-radius: 15px;
    background-size: cover;
}
.an-content-banner-inspections {
    background-image: url("../../../public/images/inspections.jpg");
    min-height: 684px;
    display: flex;
    align-items: center;
    color: white;
    padding: 40px;
    border-radius: 15px;
    background-size: cover;
}

@media (min-width: 992px) {
    

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }

    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-left {
        text-align: left !important;
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }
}
@media (max-width: 992px) {
    .mx-50{
        margin-left: 0px;
    }
    .an-content-banner-inspections,.an-content-banner-transport,.an-content-banner-shipping {
    min-height: 464px;
    display: flex;
    align-items: center;
    color: white;
    padding: 40px;
    border-radius: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    }
}



@media (max-width: 767px) {

    .mini_border_title,
    .service_content h3,
    .service_content h2,
    .service_content h4 {
        font-size: 25px;
    }
    .an-content-banner-inspections,.an-content-banner-transport,.an-content-banner-shipping {
        min-height: 230px;
        }
}