.subscribe_section {
    background: #4d4d4d;
}

.subscribe_section h3 {
    position: relative;
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
    padding-left: 50px;
    padding-top: 50px;
    padding-bottom: 22px;
    color: #fba928;
}

.subscribe_section h3:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 28px;
    height: 124px;
    background: #fba928;
}

.subscribe_section form {
    margin-top: 1.5rem;
    position: relative;
    width: 95%;
    margin-left: auto;
}

.sub_input {
    position: relative;
    width: 100% !important;
}

.sub_input:before {
    font-size: 28px;
    height: 47px;
    line-height: 47px;
    color: #fff;
}

.sub_input input {
    background: transparent;
    border: 1px solid #fff;
    color: #273846;
    height: 47px;
    width: 100%;
    border-radius: 5px;
    padding: 0 10px 0 55px;
}

.sub_input i {
    position: absolute;
    top: 0;
    left: 15px;
    font-size: 28px;
    height: 47px;
    line-height: 47px;
    color: #fff;
}

.subscribe_section button {
    margin-top: 10px !important;
    position: relative;
    width: 100%;
    height: 60px;
}

.subscribe_section button {
    background: #273846;
    color: #fff;
    border: 1px solid #fff;
    padding: 12px 6px;
    border-radius: 6px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    cursor: pointer;
}

.subscribe_section button:hover {
    outline: none;
    background: transparent;
    color: #4d4d4d;
}

@media (max-width: 767px) {
    .subscribe_section h3 {
        font-size: 24px;
        padding-left: 18px;
        padding-top: 8px;
    }
    .subscribe_section h3:before {
        width: 10px;
        height: 70px;
    }
    .subscribe_section form {
        width: 100%;
    }
    
}