.mini_border_title {
    position: relative;
    font-size: 30px;
    color: #4d4d4d;
    font-weight: 800;
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.mini_border_title:before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 50px;
    height: 5px;
    display: block;
    background: #ffb400;
}
.content_area ul li {
    position: relative;
    padding-left: 20px;
    padding-bottom: 10px;
}
.content_area ul li:before {
    content: "";
    position: absolute;
    top: 6px;
    left: 0px;
    width: 8px;
    height: 8px;
    background: #ffb400;
    display: inline-block;
    border-radius: 10px;
}
.content_area ul {
    list-style: none;
    margin: 0px;
}