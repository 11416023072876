.stock_list_banner {
    position: relative;
    width: 100%;
    min-height: 500px;
    overflow: hidden;
}

.stock_list_banner {
    background-image: url("../../../public/images/all stock banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    padding-top: 130px;
    padding-bottom: 70px;
    background-size: cover !important;
}

.stock_banner_slider {
    padding-top: 100px;
}

.products-img {
    height: 226px;
    /* min-height: 235px; */
}

.cursor {
    cursor: pointer;
}

@media (max-width:1200px) {
    .products-img {
        height: 160px;
        /* min-height: 235px; */
    }
}
@media (max-width:992px) {
    .products-img {
        height: auto;
        /* min-height: 235px; */
    }
}