.home_banner {
  background: url("../../../public/images/all stock banner.png") ;
  background-position: center;
  background-repeat: no-repeat;
}

.home_banner {
  position: relative;
  width: 100%;
  

  min-height: 500px;
  overflow: hidden;
}

video#banner_vedio {
  object-fit: cover !important;
  max-width: 100%;
}

#banner_vedio {
  position: absolute;
  /* bottom: 0px; */
  min-width: 100%;
  height: 100%;
  /* display: none; */
  right: 0px;
}

.home_banner_slider,
.banner_padding {
  padding-top: 100px;
  padding-bottom: 0px;
}

.lg_text {
  font-size: 60px;
  font-weight: 800;
  color: #fff !important;
  text-transform: uppercase;
}

.divider {
  background: #fba928;
  width: 147px;
  height: 5px;
  margin-bottom: 10px;
}

.sm_text {
  font-size: 14px;
  color: #fff;
}

.btn_banner {
  border: 1px solid #fff;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  margin-right: 10px;
}

.btn_banner:hover {
  border: 1px solid #fba928;
  color: #fba928;
  text-decoration: none;
}
.carousel-fade .carousel-item.active {
  z-index: unset;
  opacity: 1;
}

@media (max-width: 991px) {
  .lg_text,
  .border_title {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .btn_banner {
    display: block;
    text-align: center;
    margin-bottom: 12px;
  }
}

/* .animate__animated {
    background-color: bisque;
} */
