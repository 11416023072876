.equipment_banner_slider,
.banner_padding {
    padding-top: 210px;
    padding-bottom: 50px;
}
/* .wheel-loader-banner{
    background-image: url("./../../../public/images/1920\ Gradient2\ .png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    padding-top: 130px;
    padding-bottom: 70px;
    background-size: cover !important;
} */

.equipment_banner {
    position: relative;
    width: 100%;
    min-height: 750px;
    overflow: hidden;
}

.equipment_banner {
    background-position: center;
    background-repeat: no-repeat;
}

.md_text {
    font-size: 36px;
    color: #fff;
    margin: 0;
    line-height: 1.1;
}

.sorting_text {
    font-weight: 500;
    margin-bottom: 0;
}

.sort_breadcrums {
    list-style: none;
    padding: 0px;
    margin-bottom: 0px;
}

.sort_breadcrums li {
    display: inline-block;
    color: #808285;
    text-transform: uppercase;
    font-weight: 400;
}

.sort_breadcrums li a {
    color: #4d4d4d;
    text-decoration: none;
    border-right: 1px solid #4d4d4d;
    padding-right: 10px;
    margin-right: 10px;
}

.sort_breadcrums li a:hover {
    color: #ffb400;
}

.eq_product {
    border: 1px solid #4d4d4d;
    border-bottom: 5px solid #4d4d4d;
}

.eq_product .image {
    position: relative;
}

.eq_product .image img {
    width: 100%;
}

.eq_product .image p {
    position: absolute;
    bottom: 0px;
    right: 0px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    background: #4b4b4b;
    padding: 2px 15px;
}

.eq_product .content {
    position: relative;
    padding: 0px 20px 25px 40px;
}

.eq_product .content:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #ffb400;
    width: 28px;
    height: 74px;
}

.eq_product .content h3 {
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
    color: #273846;
    display: table;
    width: 100%;
    min-height: 74px;
    position: relative;
}

.eq_product .content h3 a {
    color: #4b4b4b;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.eq_product .content ul {
    list-style: none;
    margin: 0;
    padding: 0;
    min-height: 160px;
}

.eq_product .content ul li {
    position: relative;
    margin-bottom: 10px;
    line-height: 1.7;
    font-size: 14px;
    padding-left: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.eq_product .content ul li i {
    position: absolute;
    top: -3px;
    left: 0px;
    font-size: 18px;
    width: 28px;
}

.eq_product .content h3 a:hover {
    color: #ffb400;
    text-decoration: none;
}

.sold-label {
    border-top: 95px solid red;
    border-right: 95px solid transparent;
    width: 0;
    height: 0;
    position: absolute;
    z-index: 1;
}

.sold-label:before {
    content: 'Sold';
    position: absolute;
    top: -80px;
    transform: rotate(-45deg);
    font-size: 24px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    color: white;
    left: 0px;
    font-weight: 800;
}

.scroll_text {
    max-height: 400px;
    overflow-y: auto;
    padding-right: 15px;
}

.scroll_text p {
    font-size: 14px;
    color: #fff;
}

.custruction_section p {
    text-align: justify;
}

p a {
    color: #ffb400;
}

.in_tt h2 {
    color: #fff;
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
}

.form-control {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #fff;
    font-size: 14px;
    padding: 0 9px;
    border-radius: 6px;
    height: 47px !important;
}

.border_title.txt_dark {
    border-left: 22px solid #4d4d4d;
}

.txt_dark {
    color: #273846;
}

.border_title {
    position: relative;
    font-size: 58px;
    font-weight: 800;
    text-transform: uppercase;
    padding-left: 22px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.tag_section ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.tag_section ul li {
    display: inline-block;
}

.tag_section ul li a {
    color: #273846;
    font-size: 12px;
    min-width: 200px;
    display: block;
    text-align: center;
    padding: 18px 10px;
    border: 1px solid #273846;
    border-radius: 8px;
    margin-right: 8px;
    margin-bottom: 10px;
}

.tag_section ul li a:hover {
    text-decoration: none;
    color: #ffb400;
    border-color: #ffb400;
}
.wheel-loader-banner {
    background: url("../../../public/images/Banner-wheel-loader.png") #4d4d4d;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.wheel-excavators-banner {
    background-image: url("../../../public/images/Banner-wheel-excavator.png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.mini-excavators-banner {
    background-image: url("../../../public/images/Banner-excavator.png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.excavators-banner {
    background-image: url("../../../public/images/Banner-excavator.png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.track-loader-banner {
    background-image: url("../../../public/images/Banner-track-loader.png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.telehandler-banner {
    background-image: url("../../../public/images/Banner-telehandler.png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.skid-steer-banner {
    background-image: url("../../../public/images/Banner-skidsteer.png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.roller-banner {
    background-image: url("../../../public/images/banner-roller.png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.parts-banner {
    background-image: url("../../../public/images/Banner-parts.png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.dozer-banner {
    background-image: url("../../../public/images/Banner-dozer.png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.backhoe-banner {
    background-image: url("../../../public/images/Banner-backhoe.png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.attachments-banner {
    background-image: url("../../../public/images/Banner-used-attachment.png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.motor-graders-banner {
    background: url("../../../public/images/Banner-motor-Grader.png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}
.dump-truck-banner {
    background-image: url("../../../public/images/Banner-dump-truck.png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #4d4d4d;
    color: white;
    background-size: cover !important;
}

@media (max-width: 991px) {
    .form-control {
        background-color: transparent;
        border: 1px solid #ffffff;
        color: #fff;
        font-size: 10px;
        padding: 0 9px;
        border-radius: 6px;
        height: 47px !important;
    }

    .inner_title {
        font-size: 20px;
    }

    .border_title {
        font-size: 28px;
    }

    .md_text {
        font-size: 18px;
    }

    .home_banner_slider,
    .banner_padding {
        padding-top: 100px;
    }

    .equipment_banner {
        min-height: auto;
    }
}

@media (max-width: 767px) {
    
    .tag_section ul li {
        width: 100%;
    }

    .custruction_section {
        background-position: 90% 0;
    }

    .equipment_banner {
        background-position: 70% 0 !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }
}